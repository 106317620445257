import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import classes from './App.module.css';
import Spinner from './components/UI/Spinners/Ripple/Ripple';

const Connexion = lazy(() => import('./containers/Connexion/Connexion'));
const Alert = lazy(() => import('./components/Alert/Alert'));
const Register = lazy(() => import('./containers/Connexion/Enregistrement'));
const Application = lazy(() => import('./containers/Main/Application/Application'));
const Account = lazy(() => import('./components/Account/Account'));
const Apropostontinum = lazy(() => import('./containers/Details/Appropos/Tontinum'));
const Importation = lazy(() => import('./containers/Connexion/Importation'));
const Landing = lazy(() => import('./containers/Main/Landing/Landing'));
//const InPausePage = lazy(() => import('./containers/Main/Landing/InPausePage'));
const Listegroupes = lazy(() => import('./components/Plateform/Tontineachat/Listegroupes/Listegroupes'));
const Detailrotative = lazy(() => import('./containers/Details/Detailrotative'));
const Detailachat = lazy(() => import('./containers/Details/Detailachat'));
const Membrestontine = lazy(() => import('./containers/Details/Membrestontine'));
const Notifications = lazy(() => import('./components/Notifications/Notifications'));
const Epargne = lazy(() => import('./components/Plateform/Epargne/Epargne'));
const Legal = lazy(() => import('./components/Terms/Legal'));
const Use = lazy(() => import('./components/Terms/Use'));
const Vendeur = lazy(() => import('./components/Plateform/Business/Vendeur/Vendeur'));
const Accueilvendeur = lazy(() => import('./components/Plateform/Business/Vendeur/Accueilvendeur'));
const Productform = lazy(() => import('./components/Plateform/Business/Vendeur/Productform'));
const Activationvendeur = lazy(() => import('./components/Admin/Activation/Vendeur'));

const App = () => {

  return(
    <Suspense fallback={<div className={classes.Spinner}><Spinner /></div>}>
      <Switch>
        <Route path='/connexion' exact component={Connexion} />
        <Route path='/import' exact component={Importation} />
        <Route path='/enregistrement' exact component={Register} />
        <Route path='/alert' exact component={Alert} />
        <Route path='/plateforme/compte/infos' exact component={Account} />
        <Route path='/plateforme/nouvelle-tontine' exact component={Membrestontine} />
        <Route path='/plateforme/tontine-rotative/:id' exact component={Detailrotative} />
        <Route path='/plateforme/tontine-achat/:id' exact component={Detailachat} />
        <Route path='/plateforme/tontine-achat-groupes' exact component={Listegroupes} />
        <Route path='/plateforme/a-propos/tontinum' exact component={Apropostontinum} />
        <Route path='/plateforme/notifications' exact component={Notifications} />
        <Route path='/plateforme/epargne' exact component={Epargne} />
        <Route path='/plateforme' exact component={Application} />
        <Route path='/mentions-legales' exact component={Legal} />
        <Route path='/terms-of-use' exact component={Use} />
        <Route path='/plateforme/business/connexion' exact component={Vendeur} />
        <Route path='/plateforme/business/accueil' exact component={Accueilvendeur} />
        <Route path='/plateforme/business/add-products' exact component={Productform} />
        <Route path='/plateforme/activation-vendeur' exact component={Activationvendeur} />
        <Route path='/' exact component={Landing} />
        <Redirect to="/connexion" />
      </Switch>
    </Suspense>
  );
}

export default App;
