import React from 'react';

import classes from './Ripple.module.css';

const spinner = () => (
    <div className={classes.ldsRipple}>
        <div></div>
        <div></div>
    </div>
);

export default spinner;