import * as actionTypes from '../actions/actionTypes';

const initialState = {
    compte: '',
    mobile: '',
    pseudo: '',
    wallet: '',
    tontinesRotatives: null,
    tontinesAchat: null,
    offresPaniers: null,
    notifications: null,
    newNotificationsNumber: 0,
    idSiVendeur: null,
    activationSiVendeur: null,
    balance: null,
    sideDrawerStatus: false,
    vendeurPaniers: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.initialization: return { ...state, ...action.payload };
        case actionTypes.resetNotifs: return { ...state, newNotificationsNumber: 0 };
        case actionTypes.updatePaniers: return { ...state, offresPaniers: action.payload };
        case actionTypes.updateVendeurPaniers: return { ...state, vendeurPaniers: action.payload };
        case actionTypes.sideDrawerStatus: return { ...state, sideDrawerStatus: action.status };
        case actionTypes.updateBalance: return { ...state, balance: action.newBalance };
        case actionTypes.newTontine: return { ...state, tontinesRotatives: action.payload };
        case actionTypes.newTontineAchat: return { ...state, tontinesAchat: action.payload };
        case actionTypes.updatePseudo: return { ...state, pseudo: action.pseudo };
        case actionTypes.logOut: 
            return {
                ...state,
                compte: '',
                pseudo: '',
                wallet: '',
                tontinesRotatives: null,
                tontinesAchat: null,
                offresPaniers: null,
                notifications: null,
                newNotificationsNumber: 0,
                idSiVendeur: null,
                activationSiVendeur: null,
                balance: null
            }
    
        default: return state;
    }
};

export default reducer;